<script>
import appConfig from "@/app.config";
import { with_acrf_token } from '@/methods';

export default {
  components: {

  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {
    disablePassword() {
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/disable-password', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
            .then(response => {
              if(response.ok){
                return response.json();
              } else {
                if(response.status === 429) {
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('error.server.ratelimit.message'),
                    title: this.$t('error.server.ratelimit.title')
                  });
                } else
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            })
            .then(data => {
              this.$swal({
                icon: 'success',
                text: this.$t('account.security.password.disabled')
              })
              this.$emit('passwordDisabled');
            })
            .catch(error => {
              console.log(`[ERROR] ${error}`);
              this.$swal({
                icon: 'error',
                text: this.$t('account.security.password.process.error.server')
              });
            });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    }
  }
};
</script>

<template>
  <button class="btn btn-sm btn-warning" @click="disablePassword">
    <i class="fas fa-times"></i> {{ $t('account.security.password.disable') }}
  </button>
</template>